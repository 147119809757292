<template>
  <div>
    <div
      class="card mb-3"
      style="width: 100%"
      v-loading="loading"
      element-loading-text="Loading..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="card-header bg-light ">
        <h3 class="title fw-normal mb-0 p-2">
          Document Expiration &amp; Reminders
        </h3>
      </div>
      <el-form ref="form" :model="settingsData">
        <el-row class="sidemenu">
          <el-col :span="24">
            <div class="card-body ">
              <div class="p-4">
                <h4 class="fs-6 text-dark fw-medium">Expiration Settings</h4>
                <p>
                  Set the expiry date for your document validity so the system
                  does it for you automatically.
                </p>
                <div class="d-flex w-100 align-items-center">
                  <div style="max-width: 200px">Expires in</div>
                  <div class="pl-2" style="max-width: 180px">
                    <el-input
                      placeholder="0"
                      min="1"
                      type="number"
                      v-model="
                        settingsData.expiration_settings
                          .expire_documents_in_days
                      "
                      size="medium"
                    >
                      <template slot="append">
                        <i class="el-icon-date"></i> days</template
                      >
                    </el-input>
                  </div>
                  <div class="pl-1">After document is sent.</div>
                </div>
                <div class="alert alert-info d-inline-block">
                  You can adjust default expiration settings for any particular
                  document.
                </div>

                <div class="expiration-reminder-check">
                  <div class="d-flex align-items-center">
                    <el-checkbox
                      v-model="
                        settingsData.expiration_settings
                          .send_expiration_reminder_status
                      "
                    ></el-checkbox>
                    <div class="px-2" style="max-width: 200px">
                      Send first reminder
                    </div>
                    <div class="pl-2" style="max-width: 180px">
                      <el-input
                        placeholder="0"
                        min="1"
                        type="number"
                        :disabled="
                          !settingsData.expiration_settings
                            .send_expiration_reminder_status
                        "
                        v-model="
                          settingsData.expiration_settings
                            .send_first_reminder_in_days
                        "
                        size="medium"
                      >
                        <template slot="append">
                          <i class="el-icon-date"></i> days</template
                        >
                      </el-input>
                    </div>
                    <div class="pl-1">Before expiration</div>
                  </div>
                </div>

                <div class="expiration-reminder-check">
                  <div class="d-flex align-items-center">
                    <el-checkbox
                      v-model="
                        settingsData.expiration_settings
                          .repeat_expiration_reminder_status
                      "
                    ></el-checkbox>
                    <div class="px-2">Repeat reminder</div>
                    <div class="pl-2" style="max-width: 180px">
                      <el-input
                        placeholder="0"
                        min="1"
                        type="number"
                        :disabled="
                          !settingsData.expiration_settings
                            .repeat_expiration_reminder_status
                        "
                        v-model="
                          settingsData.expiration_settings
                            .repeat_expiration_reminder
                        "
                        size="medium"
                      >
                        <template slot="append">
                          <i class="el-icon-date"></i> days</template
                        >
                      </el-input>
                    </div>
                    <div class="pl-1">Before expiration</div>
                  </div>
                </div>
              </div>
            </div>
            <el-divider class="m-0"></el-divider>
            <div class="card-body">
              <div class="p-4">
                <h4 class="fs-6 text-dark fw-medium">
                  Auto reminders settings
                </h4>
                <p>
                  Automatically send email reminders to signers regarding the
                  incompletion of document.
                </p>
                <div class="d-flex w-100 align-items-center mb-1">
                  <el-checkbox
                    v-model="
                      settingsData.auto_remainders_settings
                        .first_reminder_status
                    "
                  ></el-checkbox>
                  <div class="px-2" style="max-width: 200px">
                    Send first reminder in
                  </div>
                  <div class="pl-2" style="max-width: 180px">
                    <el-input
                      placeholder="0"
                      type="number"
                      min="0"
                      :disabled="
                        !settingsData.auto_remainders_settings
                          .first_reminder_status
                      "
                      v-model="
                        settingsData.auto_remainders_settings
                          .send_first_reminder_in_days
                      "
                      size="medium"
                    >
                      <template slot="append">
                        <i class="el-icon-date"></i> days</template
                      >
                    </el-input>
                  </div>
                </div>

                <div class="expiration-reminder-check">
                  <div class="d-flex align-items-center">
                    <el-checkbox
                      v-model="
                        settingsData.auto_remainders_settings
                          .repeat_reminder_status
                      "
                    ></el-checkbox>
                    <div class="px-2">Repeat reminder</div>
                    <div class="pl-2" style="max-width: 180px">
                      <el-input
                        placeholder="0"
                        type="number"
                        min="0"
                        :disabled="
                          !settingsData.auto_remainders_settings
                            .repeat_reminder_status
                        "
                        v-model="
                          settingsData.auto_remainders_settings.repeat_reminder
                        "
                        size="medium"
                      >
                        <template slot="append">
                          <i class="el-icon-date"></i> days</template
                        >
                      </el-input>
                    </div>
                    <div class="pl-1">
                      {{
                        getIsMobile ? "Days" : "Until completed &amp; expired"
                      }}
                    </div>
                  </div>
                </div>

                <div class="alert alert-info d-inline-block">
                  You can adjust default expiration settings for any particular
                  document.
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <div class="card-footer bg-white">
          <el-form-item class="mb-0 p-2">
            <el-button @click="goToDashboard">Cancel</el-button>
            <el-button
              style="background-color: #409eff; color: #ffffff"
              class="type-2"
              @click="updateUserSettings"
              >Save Changes</el-button
            >
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
export default {
  name: "Doc-ExpirationReminder",
  data() {
    return {
      loading: true,
      settingsData: {
        expiration_settings: {
          send_expiration_reminder_status: true,
          repeat_expiration_reminder_status: true,
          expire_documents_in_days: "",
          send_first_reminder_in_days: "",
          repeat_expiration_reminder: "",
        },
        auto_remainders_settings: {
          repeat_reminder: "",
          send_first_reminder_in_days: "",
          repeat_reminder_status: true,
          first_reminder_status: true,
        },
      },
    };
  },
  async mounted() {
    await this.fetchUserSettings();
  },
  computed: {
    ...mapGetters("settings", [
      "getUserSettings",
      "getUpdateUserDocumentSettings",
    ]),
    ...mapGetters("documents", ["getDocumentSettings"]),
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
  },
  methods: {
    ...mapActions({
      fetchDocumentSettings: "documents/fetchDocumentSettings",
    }),
    async fetchUserSettings() {
      try {
        this.loading = true;
        await this.$store.dispatch("settings/fetchUserDocumentSettings");
        this.setUserSettings();
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log("fetchUserSettings", err);
      }
    },
    async updateUserSettings() {
      try {
        this.loading = true;
        await this.$store.dispatch("settings/updateUserDocumentSettings", {
          document_expiration_reminders: this.settingsData,
        });
        if (this.getUpdateUserDocumentSettings) {
          this.successNotify("Updated user documents settings successfully");
        } else {
          this.errorNotify("Error in updating user documents settings");
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log("updateUserSettings", err);
      }
    },
    setUserSettings() {
      let data = this.getUserSettings;

      this.settingsData.expiration_settings.send_expiration_reminder_status =
        data.expiration_settings.send_expiration_reminder_status;
      this.settingsData.expiration_settings.repeat_expiration_reminder_status =
        data.expiration_settings.repeat_expiration_reminder_status;
      this.settingsData.expiration_settings.expire_documents_in_days =
        data.expiration_settings.expire_documents_in_days;
      this.settingsData.expiration_settings.send_first_reminder_in_days =
        data.expiration_settings.send_first_reminder_in_days;
      this.settingsData.expiration_settings.repeat_expiration_reminder =
        data.expiration_settings.repeat_expiration_reminder;
      this.settingsData.auto_remainders_settings.repeat_reminder =
        data.auto_remainders_settings.repeat_reminder;
      this.settingsData.auto_remainders_settings.send_first_reminder_in_days =
        data.auto_remainders_settings.send_first_reminder_in_days;
      this.settingsData.auto_remainders_settings.repeat_reminder_status =
        data.auto_remainders_settings.repeat_reminder_status;
      this.settingsData.auto_remainders_settings.first_reminder_status =
        data.auto_remainders_settings.first_reminder_status;
    },
    goToDashboard() {
      this.setUserSettings()
    },
  },
  beforeDestroy() {
    // this.$store.commit(
    // "settings/setUserSettings", null,{root:true})
    // this.$store.commit("settings/setUpdateUserDocumentSettings", null,{root:true})
    // this.$store.commit("documents/setDocumentSettings",null,
    //  {
    //   root: true,
    // }
    // );
  },
};
</script>

<style lang="scss" scoped>
.sidemenu{
  height: 510px;
  overflow-y: scroll;
  scrollbar-width: thin;
  border-top:1px ridge;
  border-left: 1px ridge;
  border-bottom: 1px ridge;
  margin-right: 5px;
}
.sidemenu::-webkit-scrollbar {
   width: 0.7em;
}
.sidemenu::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    background: #e1e1e1;
    -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9); 
}
.sidemenu::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #F5F5F5; 
    background-color: #F5F5F5;

}
.expiration-reminder-check {
  margin-bottom: 15px;
}
</style>
